import React from "react"
import { CartProvider } from "./src/context/CartProvider"
import { CheckoutProvider } from "./src/context/CheckoutProvider"
import { CustomerProvider } from "./src/context/CustomerProvider"

export const wrapRootElement = ({ element }) => {
  return (
    <CheckoutProvider>
      <CartProvider>
        <CustomerProvider>{element}</CustomerProvider>
      </CartProvider>
    </CheckoutProvider>
  )
}
