import React, { createContext, useState, useEffect } from "react"

const CheckoutContext = createContext()

const checkoutInclude =
  "cart.line_items.physical_items.options,consignments.available_shipping_options,consignments.selected_shipping_options,coupons"

export const CheckoutProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [step, setStep] = useState(1)
  const [checkoutErr, setCheckoutErr] = useState(false)
  const [checkout, setCheckout] = useState(null)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [orderID, setOrderID] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState([])
  const [orderComment, setOrderComment] = useState("")

  const refreshCheckout = response => {
    if (response?.status) {
      console.log(response.status, response)
    }

    let totalItems = 0
    const { physical_items } = response.data.cart.line_items
    physical_items.forEach(item => {
      totalItems += item.quantity
    })

    setCheckout({
      ...response.data,
      cart: { ...response.data.cart, totalItems },
    })
  }

  function fetchCheckout() {
    setIsLoading(true)

    return fetch(`/.netlify/functions/bigcommerce?endpoint=checkouts`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(res => res.json())
      .then(response => refreshCheckout(response))
      .catch(error => {
        console.error(error)
      })
      .finally(() => setIsLoading(false))
  }

  function addCouponToCheckout(coupon_code) {
    const checkoutId = checkout?.id
    if (checkoutId) {
      return fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/coupons?include=${checkoutInclude}`,
        {
          credentials: "same-origin",
          mode: "same-origin",
          method: "POST",
          body: JSON.stringify({ coupon_code }),
        }
      )
        .then(res => res.json())
        .then(async response => {
          if (step === 4) await createOrder()
          refreshCheckout(response)
        })
        .catch(err => console.log("[ERR]", err))
    }
  }

  function deleteCouponToCheckout(couponCode) {
    const checkoutId = checkout?.id
    if (checkoutId) {
      return fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/coupons/${couponCode}?include=${checkoutInclude}`,
        {
          credentials: "same-origin",
          mode: "same-origin",
          method: "DELETE",
        }
      )
        .then(res => res.json())
        .then(async response => {
          if (step === 4) await createOrder()
          refreshCheckout(response)
        })
        .catch(err => console.log("[ERR]", err))
    }
  }

  function createOrder() {
    const checkoutId = checkout?.id
    if (checkoutId) {
      setOrderID(null)
      setCheckoutErr(false)

      return fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/orders`,
        {
          credentials: "same-origin",
          mode: "same-origin",
          method: "POST",
          body: JSON.stringify(null),
        }
      )
        .then(res => res.json())
        .then(response => {
          if (response.status) {
            setCheckoutErr([response.title])
          } else setOrderID(response?.data?.id)
        })
        .catch(error => console.error(error))
    }
  }

  function updateOrder(payload, order_id) {
    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v2/orders/${order_id}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log("[ok]-updated order: ", response)
      })
      .catch(error => console.error(error))
  }

  function fetchBillingAddress(payload, addressId) {
    const checkoutId = checkout?.id
    if (checkoutId) {
      let url = `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/billing-address?include=${checkoutInclude}`
      if (addressId) {
        url = `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/billing-address/${addressId}?include=${checkoutInclude}`
      }

      setIsFetching(true)
      return fetch(url, {
        credentials: "same-origin",
        mode: "same-origin",
        method: addressId ? "PUT" : "POST",
        body: JSON.stringify(payload),
      })
        .then(res => res.json())
        .then(response => {
          refreshCheckout(response)
          setStep(prev => prev + 1)
        })
        .catch(error => {
          console.error(error)
          // setCheckoutErr({ error })
        })
        .finally(() => setIsFetching(false))
    }
  }

  function fetchConsignmentAddress(payload, consignmentId) {
    const checkoutId = checkout?.id
    if (checkoutId) {
      let url = `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/consignments?include=${checkoutInclude}`
      if (consignmentId) {
        url = `/.netlify/functions/bigcommerce?endpoint=/v3/checkouts/${checkoutId}/consignments/${consignmentId}?include=${checkoutInclude}`
      }

      setIsFetching(true)
      return fetch(url, {
        credentials: "same-origin",
        mode: "same-origin",
        method: consignmentId ? "PUT" : "POST",
        body: JSON.stringify(payload),
      })
        .then(res => res.json())
        .then(response => {
          refreshCheckout(response)
        })
        .catch(error => {
          console.error(error)
          // setCheckoutErr({ error })
        })
        .finally(() => setIsFetching(false))
    }
  }

  const getSubscriber = email => {
    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/customers/subscribers?email=${email}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
      }
    ).then(res => res.json())
  }

  const createSubscriber = async email => {
    try {
      const subscriber = await getSubscriber(email)
      if (subscriber.data.length === 0) {
        await fetch(
          `/.netlify/functions/bigcommerce?endpoint=/v3/customers/subscribers?email=${email}`,
          {
            credentials: "same-origin",
            mode: "same-origin",
            method: "POST",
            body: JSON.stringify({ email }),
          }
        ).then(res => {
          if (res.status === 200)
            console.log(`${email} was successfully subscribed`)
        })
      } else {
        console.log(`${email} has been subscribed`)
      }
    } catch (error) {
      console.error(error)
      // setCheckoutErr({ error })
    }
  }

  function getAllCountryStates(country_id) {
    setStates([])

    if (country_id) {
      return fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v2/countries/${country_id}/states?limit=100`,
        {
          credentials: "same-origin",
          mode: "same-origin",
        }
      )
        .then(res => res.status === 200 && res.json())
        .then(response => {
          if (response) setStates(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  async function initCheckout() {
    try {
      setIsLoading(true)
      setCountries([])

      //get shipping zones
      const zones = await fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v2/shipping/zones`,
        {
          credentials: "same-origin",
          mode: "same-origin",
        }
      ).then(res => res.json())
      const shipping_zones = zones.map(zone => zone.name)

      //get countries
      for (let i = 0; i < shipping_zones.length; i++) {
        const zone_name = shipping_zones[i]
        const country = await fetch(
          `/.netlify/functions/bigcommerce?endpoint=/v2/countries?country=${zone_name}`,
          {
            credentials: "same-origin",
            mode: "same-origin",
          }
        ).then(res => res.json())
        if (country.length) setCountries(prev => [...prev, country[0]])
      }

      await fetchCheckout()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    async function init() {
      if (step === 4) {
        if (orderID) {
          if (orderComment) {
            await updateOrder({ customer_message: orderComment }, orderID)
          }
          await getAcceptedPaymentMethod(orderID)
        }
      }
    }

    init()
  }, [orderID, orderComment, step])

  function getAcceptedPaymentMethod(orderID) {
    setPaymentMethod([])
    setIsFetching(true)

    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/payments/methods?order_id=${orderID}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
      }
    )
      .then(res => res.json())
      .then(response => {
        if (response?.status) {
          setCheckoutErr(Object.values(response.errors))
        } else {
          setPaymentMethod(response?.data)
        }
      })
      .catch(error => {
        console.error(error)
        // setCheckoutErr({ error })
      })
      .finally(() => setIsFetching(false))
  }

  async function processPaymentStep() {
    try {
      setIsFetching(true)
      await createOrder()
    } catch (error) {
      console.log(error)
    }
  }

  function processPayment(payload) {
    setCheckoutErr(false)

    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/payments/access_tokens`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "POST",
        body: JSON.stringify({
          order: {
            id: orderID,
            is_recurring: false,
          },
        }),
      }
    )
      .then(res => res.json())
      .then(async response => {
        if (response?.status) {
          console.log("[ERR]", response)
          setCheckoutErr(Object.values(response.errors))
        } else {
          const payment = await fetch(
            `/.netlify/functions/bigcommerce?endpoint=payments`,
            {
              credentials: "same-origin",
              mode: "same-origin",
              method: "POST",
              body: JSON.stringify(payload),
              headers: { Authorization: `PAT ${response?.data?.id}` },
            }
          ).then(res => res.json())

          if (payment.status) {
            console.log("[err]", payment)
            setCheckoutErr(Object.values(payment.errors))
          } else {
            console.log("[ok]", payment)
            window.location.replace(`/order-confirmation?order_id=${orderID}`)
          }
        }
      })
      .catch(error => console.error(error))
  }

  return (
    <CheckoutContext.Provider
      value={{
        isFetching,
        isLoading,
        step,
        setStep,
        checkoutErr,
        checkout,
        countries,
        states,
        fetchCheckout,
        fetchBillingAddress,
        fetchConsignmentAddress,
        createSubscriber,
        getAllCountryStates,
        processPaymentStep,
        paymentMethod,
        processPayment,
        addCouponToCheckout,
        deleteCouponToCheckout,
        orderComment,
        setOrderComment,
        initCheckout,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutContext
