import React, { createContext, useEffect, useState, useContext } from "react"
import CartContext from "./CartProvider"
import CheckoutContext from "./CheckoutProvider"

const CustomerContext = createContext()

export const CustomerProvider = ({ children }) => {
  const cart_value = useContext(CartContext)
  const checkout_value = useContext(CheckoutContext)

  const [loading, setLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [customerErr, setCustomerErr] = useState(false)
  const [customerSuccess, setCustomerSuccess] = useState(false)
  const [state, setState] = useState({
    addressId: "",
  })
  const [countries, setCountries] = useState([])

  function fetchCustomer() {
    return fetch(`/.netlify/functions/bigcommerce?endpoint=customers`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(res => res.json())
      .then(response => {
        if (response.data.length) setCustomer(response.data[0])
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  function fetchSigninCustomer(id) {
    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=customers/signin&customerId=${id}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
      }
    )
      .then(res => res.json())
      .then(response => {
        if (response.data.length) setCustomer(response.data[0])
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  function getCustomerByEmail(email) {
    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/customers?email:in=${email}`,
      { credentials: "same-origin", mode: "same-origin" }
    ).then(res => res.json())
  }

  function createCustomer(payload) {
    setIsFetching(true)
    setCustomerErr(false)

    fetch(`/.netlify/functions/bigcommerce?endpoint=/v3/customers`, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then(res => res.json())
      .then(async response => {
        if (response.status === 422) {
          setCustomerErr(Object.values(response.errors))
        } else {
          if (response?.data?.length) {
            setCustomer(response.data[0])
            await cart_value.updateCart(response.data[0].id)
            await checkout_value.fetchCheckout()
            checkout_value.setStep(2)
          }
        }
      })
      .catch(err => {
        console.log(err)
        // setCustomerErr(err)
      })
      .finally(() => setIsFetching(false))
  }

  async function updateCustomer(payload) {
    try {
      setIsFetching(true)
      setCustomerErr(false)
      setCustomerSuccess(false)

      const newpayload = {
        id: payload.id,
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        company: payload.company,
        phone: payload.phone,
      }

      let isValid = true
      if (
        payload?.password &&
        payload?.confirmPassword &&
        payload?.currentPassword
      ) {
        const check_credentials = await fetch(
          `/.netlify/functions/bigcommerce?endpoint=/v3/customers/validate-credentials`,
          {
            credentials: "same-origin",
            mode: "same-origin",
            method: "POST",
            body: JSON.stringify({
              email: payload.email,
              password: payload.currentPassword,
            }),
          }
        ).then(res => res.json())

        if (check_credentials.is_valid) {
          newpayload.authentication = {
            force_password_reset: false,
            new_password: payload.password,
          }
        } else {
          setCustomerErr(["Invalid password."])
        }

        isValid = check_credentials.is_valid
      }

      if (isValid) {
        const new_customer = await fetch(
          `/.netlify/functions/bigcommerce?endpoint=/v3/customers?include=addresses`,
          {
            credentials: "same-origin",
            mode: "same-origin",
            method: "PUT",
            body: JSON.stringify([newpayload]),
          }
        ).then(res => res.json())

        if (new_customer.status === 422) {
          setCustomerErr(Object.values(new_customer.errors))
        } else {
          if (new_customer?.data?.length) {
            setCustomer(prev => ({
              ...new_customer.data[0],
              addresses: prev.addresses,
            }))

            setCustomerSuccess(["Customer updated successfully"])
          }
        }
      }
    } catch (error) {
      console.log("[ERR]", error)
    } finally {
      setIsFetching(false)
    }
  }

  function signoutCustomer() {
    setIsFetching(true)

    fetch(`/.netlify/functions/bigcommerce?endpoint=customers/signout`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(res => res.json())
      .then(async response => {
        setCustomer(null)
        await cart_value.updateCart(0)
        checkout_value.setStep(1)
        await checkout_value.fetchCheckout()
      })
      .catch(err => {
        console.log(err)
        // setCustomerErr(err)
      })
      .finally(() => setIsFetching(false))
  }

  function signinCustomer(payload) {
    setIsFetching(true)
    setCustomerErr(false)

    fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/customers/validate-credentials`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then(res => res.json())
      .then(async response => {
        if (response?.status) {
          setCustomerErr(Object.values(response.errors))
        } else {
          if (response?.is_valid) {
            await fetchSigninCustomer(response.customer_id)
            await cart_value.updateCart(response.customer_id)
            await checkout_value.fetchCheckout()
            checkout_value.setStep(2)
          } else {
            setCustomerErr(["Invalid username or password"])
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsFetching(false))
  }

  async function getCountries() {
    setCountries([])

    //get shipping zones
    const zones = await fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v2/shipping/zones`,
      {
        credentials: "same-origin",
        mode: "same-origin",
      }
    ).then(res => res.json())
    const shipping_zones = zones.map(zone => zone.name)

    //get countries
    for (let i = 0; i < shipping_zones.length; i++) {
      const zone_name = shipping_zones[i]
      const country = await fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v2/countries?country=${zone_name}`,
        {
          credentials: "same-origin",
          mode: "same-origin",
        }
      ).then(res => res.json())
      if (country.length) setCountries(prev => [...prev, country[0]])
    }
  }

  function getStates(url) {
    return fetch(`/.netlify/functions/bigcommerce?endpoint=/v2${url}`, {
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(res => res.json())
      .catch(err => {
        console.log(err)
      })
  }

  function createCustomerAddress(payload) {
    setIsFetching(true)

    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/customers/addresses`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then(res => res.json())
      .then(async response => {
        if (response?.status) {
          console.log("[error]-", response)
          setCustomerErr(Object.values(response.errors))
        } else {
          console.log("[ok]-", response)
          const foundAddress = customer.addresses.find(
            add => add.id === response.data[0].id
          )
          if (!foundAddress) {
            setCustomer(prev => ({
              ...prev,
              addresses: [...prev.addresses, response.data[0]],
            }))
          } else {
            console.log("Duplicate address.")
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsFetching(false))
  }

  function updateCustomerAddress(payload) {
    setIsFetching(true)

    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/customers/addresses`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "PUT",
        body: JSON.stringify(payload),
      }
    )
      .then(res => res.json())
      .then(async response => {
        if (response?.status) {
          console.log("[error]-", response)
          setCustomerErr(Object.values(response.errors))
        } else {
          console.log("[ok]-", response)
          const newAddress = response.data[0]

          setCustomer(prev => ({
            ...prev,
            addresses: prev.addresses.map(el =>
              el.id === newAddress.id ? newAddress : el
            ),
          }))
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsFetching(false))
  }

  function deleteCustomerAddress(id) {
    setState(prev => ({ ...prev, addressId: id }))

    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v3/customers/addresses?id:in=${id}`,
      {
        credentials: "same-origin",
        mode: "same-origin",
        method: "DELETE",
      }
    )
      .then(res => {
        if (res.status === 204) {
          setCustomer(prev => ({
            ...prev,
            addresses: prev.addresses.filter(el => el.id !== id),
          }))
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setState(prev => ({ ...prev, addressId: null })))
  }

  function sendResetPassLink(payload) {
    return fetch(`/.netlify/functions/sendResetPassLink`, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "POST",
      body: JSON.stringify(payload),
    })
  }

  function verifyResetPassToken(token) {
    return fetch(`/.netlify/functions/verifyResetPassToken?token=${token}`, {
      credentials: "same-origin",
      mode: "same-origin",
    }).then(res => res.json())
  }

  function resetPassword(payload) {
    return fetch(`/.netlify/functions/bigcommerce?endpoint=/v3/customers`, {
      credentials: "same-origin",
      mode: "same-origin",
      method: "PUT",
      body: JSON.stringify(payload),
    }).then(res => res.json())
  }

  useEffect(() => fetchCustomer(), [])

  return (
    <CustomerContext.Provider
      value={{
        loading,
        isFetching,
        customer,
        customerErr,
        customerSuccess,
        state,
        countries,
        setCustomerErr,
        createCustomer,
        updateCustomer,
        signoutCustomer,
        signinCustomer,
        createCustomerAddress,
        updateCustomerAddress,
        deleteCustomerAddress,
        getCustomerByEmail,
        sendResetPassLink,
        verifyResetPassToken,
        resetPassword,
        getCountries,
        getStates,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerContext
